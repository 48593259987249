// input[type="checkbox"]:checked:after {
//   content: "\2713";
//   color: #fff;
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   -webkit-transform: translate(-50%, -50%);
//   -moz-transform: translate(-50%, -50%);
//   -ms-transform: translate(-50%, -50%);
//   transform: translate(-50%, -50%);
//   outline: 1px solid #A9A9A9;
// }

body {
  font-family: $pb-font-light;
  background-color: $pb-color-theme-background;
  color: $pb-color-content;
}

::-webkit-scrollbar {
  width: 5px;
  display: block !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid $pb-color-primary;
  // -webkit-text-fill-color: $pb-color-primary;
  -webkit-text-fill-color: $pb-color-dark;
  -webkit-box-shadow: 0 0 0px 1000px $pb-color-tertiary inset;
  transition: background-color 5000s ease-in-out 0s;
}

button:focus {
  outline: 0;
}


input,
input.form-control,
select,
select.form-control,
input,
input.form-control,
select,
select.form-control,
.btn {
  border-radius: 20px;
  height: calc(2.0625rem + 7px);
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.react-bootstrap-daterangepicker-container {
  input.form-control {
    min-width: 215px;
  }
}

select.form-control {
  padding-right: $pb-small-padding*1.4;
  padding-left: $pb-small-padding*1.4;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('../assets/img/angle.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
}

.btn.btn-block {
  min-height: 50px;
  border-radius: 25px;
}

.color-black {
  color: black;
}

.helpingText {
  margin-left: 5px;
  cursor: pointer;
}

.conditionHelpText {
  font-weight: normal;
  color: grey;
}


/* loader-css-start */

.full-page-loader {
  text-align: center;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 33px;
  height: 33px;
  vertical-align: middle;
}

.lds-spinner div {
  transform-origin: 16px 16px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 2px;
  left: 15px;
  width: 3px;
  height: 7px;
  border-radius: 40%;
  background: #e84a43;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


/** ================= PB css ============**/


/** ===== button css **/
.btn {
  font-size: $pb-root-font-size;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;

  &:focus,
  &.focus,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
}

.btn-primary {
  background: $pb-color-secondary;
  font-family: $pb-font-medium;
  font-size: 18px;
  border-radius: 30px !important;
  background: rgb(41, 201, 150);
  color: $pb-color-tertiary !important;
  background: -moz-linear-gradient(left, rgba(41, 201, 150, 1) 0%, rgba(26, 131, 97, 1) 100%);
  background: -webkit-linear-gradient(left, rgba(41, 201, 150, 1) 0%, rgba(26, 131, 97, 1) 100%);
  background: linear-gradient(to right, rgba(41, 201, 150, 1) 0%, rgba(26, 131, 97, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#29c996', endColorstr='#1a8361', GradientType=1);
  text-transform: uppercase;
  border: 0;

  &:hover,
  &:not(:disabled):not(.disabled):active,
  &.disabled,
  &:disabled {
    background-color: $pb-color-primary-hover;
    border-color: $pb-color-primary-hover;
  }

  &:hover {
    background: rgb(41, 201, 150);
    background: -moz-linear-gradient(left, rgba(41, 201, 150, 1) 0%, rgba(26, 131, 97, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(41, 201, 150, 1) 0%, rgba(26, 131, 97, 1) 100%);
    background: linear-gradient(to right, rgba(41, 201, 150, 1) 0%, rgba(26, 131, 97, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#29c996', endColorstr='#1a8361', GradientType=1);
    color: $pb-color-tertiary;
  }

  &:active, &:focus {
    color: $pb-color-tertiary !important;
  }

}

.btn-secondary {
  width: 98%;
  font-size: 20px;
  border: 0;
  margin: 10px auto 10px auto;
  display: block;
  border-radius: 10px !important;
  height: 50px !important;
  color: $pb-color-tertiary !important;
  background: rgb(40, 199, 148);
  background: -moz-linear-gradient(left, rgba(40, 199, 148, 1) 0%, rgba(26, 130, 96, 1) 100%);
  background: -webkit-linear-gradient(left, rgba(40, 199, 148, 1) 0%, rgba(26, 130, 96, 1) 100%);
  background: linear-gradient(to right, rgba(40, 199, 148, 1) 0%, rgba(26, 130, 96, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#28c794', endColorstr='#1a8260', GradientType=1);

  &:hover {
    background: rgb(40, 199, 148);
    background: -moz-linear-gradient(left, rgba(40, 199, 148, 1) 0%, rgba(26, 130, 96, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(40, 199, 148, 1) 0%, rgba(26, 130, 96, 1) 100%);
    background: linear-gradient(to right, rgba(40, 199, 148, 1) 0%, rgba(26, 130, 96, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#28c794', endColorstr='#1a8260', GradientType=1);
    color: $pb-color-tertiary;
  }

  &:active, &:focus {
    color: $pb-color-tertiary !important;
  }

  &:disabled,
  &.diabled,
  &:disabled:hover,
  &.diabled:hover {
    background-color: $pb-color-primary-hover;
    border-color: $pb-color-primary-hover;
    color: #a7c493;
  }
}

/** ==== modal css **/
.modal-content {
  .modal-header {
    padding-bottom: 0;

    .modal-title {
      font-weight: $pb-font-weight-bold;
    }

    .modalClose {
      position: absolute;
      right: 25px;
      padding: 0;
      height: auto !important;
      background-color: transparent;
      color: black;
      border: none;

      span {
        font-size: 1.625rem;
        line-height: 1.625rem;
      }

      &:active {
        background-color: transparent;
      }
    }

    .close {
      padding: 6px 0;
    }

  }

  .modal-header,
  .modal-body,
  .modal-footer {
    padding-left: $pb-large-padding + $pb-base-padding;
    padding-right: $pb-large-padding + $pb-base-padding;
  }

  .modal-header,
  .modal-footer {
    border: 0;
  }

  .modal-body {
    padding-top: 0;
    font-size: 15px;

    .form-group {
      position: relative;

      .error-message {
        right: 0;
      }
    }
  }

  .modal-footer {
    justify-content: center;
    padding-top: $pb-base-padding;


    .btn {
      min-width: 100px;
    }
  }

  .modal-title {
    font-size: 20px;
    font-family: $pb-font-semibold;
  }

  .modal-body {
    line-height: 19px;
  }

}

.modal-dialog {
  top: 50%;
  margin: 0 auto;
  transform: translate(0, -50%) !important;
  width: 92%;
}

/** === accordian css **/
.card {
  background: $pb-color-tertiary;
  padding: 10px 15px;
  border: 0;
  border-radius: 0;
  margin-bottom: 0;
  border-bottom: 1px solid $pb-color-secondary-border;

  .card-header {
    background: none;
    padding: 0;
    margin: 0;
    position: relative;
    border: 0;
    border-radius: 0;

    .label {
      font-size: 18px;
      font-family: $pb-font-bold;
      width: 90%;
      line-height: 18px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }


    .arrow-icon {
      position: absolute;
      right: 0;
      top: -3px;

      svg {
        fill: $pb-color-primary;
        width: 18px;
      }
    }

  }


  .card-body {
    padding: 0;
    width: 95%;

    .detail-view-box {
      font-size: 18px;
      color: $pb-color-gray-2;
      margin-top: 10px;
    }

  }

}

/** form css **/
.form-check-label {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;


  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #e5e5e5;
    border-radius: 50%;

    &:after {

      position: absolute;
      display: none;
    }
  }


  input:checked ~ .checkmark {
    background: $pb-color-primary;
  }

  input:checked ~ .checkmark:after {
    display: block;
    content: "\2713";
    // font-size: 12px;
    // content: "\f00c";
  }

  .checkmark:after {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    // font-family: 'Font Awesome 5 Pro';
    // font-weight: 400;
    color: $pb-color-tertiary;
    // content: "\2713";
    font-size: 18px;
    position: absolute;
    left: 3px;
    top: 2px;
  }

}

/** progress bar css **/
.progress-bar-box {
  overflow: hidden;


  .progress-bar-parent {
    background: #e4e4e4;
    border-radius: 20px;
    overflow: hidden;
    float: left;
    width: 75%;
    margin-right: 5%;
    margin-top: 5px;

    .progress-bar {
      display: block;
      height: 10px;
      border-radius: 20px;
      background: #29cb97;
    }

  }

  .value-box {
    float: right;
    width: 20%;
    font-size: 14px;
    color: #29cb97;
  }

}

/** tab css **/
.nav-tabs {
  background: #f7f7f7;
  justify-content: space-around;
  flex-direction: row;
  display: flex;

  .nav-item {
    flex-grow: 1;

    .nav-link {
      font-size: 19px;
      color: $pb-color-primary;
      border: 0;
      border-bottom: 5px solid transparent;
      text-align: center;


      &.active {
        background: none;
        border-bottom: 4px solid $pb-color-secondary;
        color: #3f3f3f;
        font-family: $pb-font-semibold;
      }
    }
  }


}

.tab-content {
  border: 0;
  min-height: 100%;


  .tab-pane {
    padding: 0;
  }


  .sectionlist-container {
    padding-top: 0;
  }

  .section-container {
    margin-bottom: 0;
  }

  .section-item {
    display: block;
    border: 0;
    height: auto;
    padding: 0;
  }

}

/** bottom invinite loader **/
.bottom-infinite-loader {
  .spinner-border {
    border: 0;
    background: url(../assets/img/infinite-loader.gif) no-repeat;
    width: 70px;
    height: 70px;
    background-size: 100%;
    margin: 0 auto;
    animation: unset;
    display: block;
  }

  .bottom-txt {
    text-align: center;
    color: $pb-color-primary;
    font-size: 15px;
    margin-top: -10px;
  }

}

.dropdown-header {
  outline: none !important;
  padding: 0;

  > div {
    padding: 8px 20px;
  }
}


@media (max-width: 500px) {
  .btn-secondary {
    width: 90%;
  }
}









