@import "../../../scss/variables";


.feedyard-top-status-bar {
  background: #f2f2f2;
  font-size: 12px;
  font-family: $pb-font-bold;
  padding: 8px 20px;
  color: $pb-color-gray-2;
}

.feedyars-list-box {
  background: $pb-color-tertiary;

  .list {
    border-bottom: 1px solid $pb-border-color-gray;
    padding: 10px 34px 10px 20px;
    position: relative;
    @include transitions(0.5s);

    .icon {
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 11px;
      height: 18px;
      text-align: center;

      i {
        font-size: 16px;
        line-height: 0;
      }
    }


    &:hover {
      background: #ececec;
    }
  }
  .name {
    color:$pb-color-dark-2;
    font-size: 18px;
    font-family: $pb-font-bold;
    line-height: 19px;
    margin-bottom: 5px;
  }
  .address {
    color: $pb-color-gray-2;
    font-size: 12px;
    line-height: 16px;
  }
}

