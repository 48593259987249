@font-face {
    font-family: 'SF UI Display Thin';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Display Thin'), url('../assets/fonts/sf-ui-display-thin-58646e9b26e8b.woff') format('woff');
}


@font-face {
    font-family: 'SF UI Display Light';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Display Light'), url('../assets/fonts/sf-ui-display-light-58646b33e0551.woff') format('woff');
}


@font-face {
    font-family: 'SF UI Display Medium';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Display Medium'), url('../assets/fonts/sf-ui-display-medium-58646be638f96.woff') format('woff');
}


@font-face {
    font-family: 'SF UI Display Semibold';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Display Semibold'), url('../assets/fonts/sf-ui-display-semibold-58646eddcae92.woff') format('woff');
}


@font-face {
    font-family: 'SF UI Display Bold';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Display Bold'), url('../assets/fonts/sf-ui-display-bold-58646a511e3d9.woff') format('woff');
}


@font-face {
    font-family: 'SF UI Display Black';
    font-style: normal;
    font-weight: normal;
    src: local('SF UI Display Black'), url('../assets/fonts/sf-ui-display-black-58646a6b80d5a.woff') format('woff');
}



@font-face {
    font-family: 'icomoon';
    src: url('../assets/fonts/icomoon.eot?iiv8lt');
    src: url('../assets/fonts/icomoon.eot?iiv8lt#iefix') format('embedded-opentype'), url('../assets/fonts/icomoon.ttf?iiv8lt') format('truetype'), url('../assets/fonts/icomoon.woff?iiv8lt') format('woff'), url('../assets/fonts/icomoon.svg?iiv8lt#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* [class^="ecoIcon-"], [class*=" ecoIcon-"] { */

//.ecoIcon {
//    /* use !important to prevent issues with browser extensions that change fonts */
//    font-family: 'icomoon' !important;
//    speak: none;
//    font-style: normal;
//    font-weight: normal;
//    font-variant: normal;
//    text-transform: none;
//    line-height: 1;
//    /* Better Font Rendering =========== */
//    -webkit-font-smoothing: antialiased;
//    -moz-osx-font-smoothing: grayscale;
//}
//
//.ecoIcon-scheduled-tasks:before {
//    @extend .ecoIcon;
//    content: "\e92a";
//  }
//.ecoIcon-cross:before {
//    @extend .ecoIcon;
//    content: "\e928";
//  }
//  .ecoIcon-img-upload:before {
//    @extend .ecoIcon;
//    content: "\e929";
//  }
//
//.ecoIcon-add:before {
//    @extend .ecoIcon;
//    content: "\e900";
//}
//
//.ecoIcon-align-center:before {
//    @extend .ecoIcon;
//    content: "\e91e";
//}
//
//.ecoIcon-align-left:before {
//    @extend .ecoIcon;
//    content: "\e91f";
//}
//
//.ecoIcon-align-right:before {
//    @extend .ecoIcon;
//    content: "\e920";
//}
//
//.ecoIcon-angle:before {
//    @extend .ecoIcon;
//    content: "\e901";
//}
//
//.ecoIcon-arrow:before {
//    @extend .ecoIcon;
//    content: "\e902";
//}
//
//.ecoIcon-ascending-order:before {
//    @extend .ecoIcon;
//    content: "\e91c";
//}
//
//.ecoIcon-blockquote:before {
//    @extend .ecoIcon;
//    content: "\e921";
//}
//
//.ecoIcon-bold:before {
//    @extend .ecoIcon;
//    content: "\e922";
//}
//
//.ecoIcon-bullet:before {
//    @extend .ecoIcon;
//    content: "\e923";
//}
//
//.ecoIcon-bullet-number:before {
//    @extend .ecoIcon;
//    content: "\e924";
//}
//
//.ecoIcon-calendar:before {
//    @extend .ecoIcon;
//    content: "\e903";
//}
//
//.ecoIcon-chart:before {
//    @extend .ecoIcon;
//    content: "\e904";
//}
//
//.ecoIcon-checkbox-check:before {
//    @extend .ecoIcon;
//    content: "\e905";
//}
//
//.ecoIcon-checkbox-uncheck:before {
//    @extend .ecoIcon;
//    content: "\e906";
//}
//
//.ecoIcon-clock:before {
//    @extend .ecoIcon;
//    content: "\e907";
//}
//
//.ecoIcon-decending-order:before {
//    @extend .ecoIcon;
//    content: "\e91d";
//}
//
//.ecoIcon-filter:before {
//    @extend .ecoIcon;
//    content: "\e908";
//}
//
//.ecoIcon-home:before {
//    @extend .ecoIcon;
//    content: "\e909";
//}
//
//.ecoIcon-italic:before {
//    @extend .ecoIcon;
//    content: "\e925";
//}
//
//.ecoIcon-link:before {
//    @extend .ecoIcon;
//    content: "\e926";
//}
//
//.ecoIcon-menu:before {
//    @extend .ecoIcon;
//    content: "\e90a";
//}
//
//.ecoIcon-more:before {
//    @extend .ecoIcon;
//    content: "\e90b";
//}
//
//.ecoIcon-move:before {
//    @extend .ecoIcon;
//    content: "\e90c";
//}
//
//.ecoIcon-open-task:before {
//    @extend .ecoIcon;
//    content: "\e90d";
//}
//
//.ecoIcon-pencil:before {
//    @extend .ecoIcon;
//    content: "\e90e";
//}
//
//.ecoIcon-print:before {
//    @extend .ecoIcon;
//    content: "\e90f";
//}
//
//.ecoIcon-radio-check:before {
//    @extend .ecoIcon;
//    content: "\e910";
//}
//
//.ecoIcon-radio-uncheck:before {
//    @extend .ecoIcon;
//    content: "\e911";
//}
//
//.ecoIcon-search:before {
//    @extend .ecoIcon;
//    content: "\e912";
//}
//
//.ecoIcon-sop:before {
//    @extend .ecoIcon;
//    content: "\e913";
//}
//
//.ecoIcon-program:before {
//    @extend .ecoIcon;
//    content: "\e923";
//}
//
//.ecoIcon-scheduled-task:before {
//    @extend .ecoIcon;
//    content: "";
//}
//
//.ecoIcon-task-history:before {
//    @extend .ecoIcon;
//    content: "\e907";
//}
//
//.ecoIcon-task:before {
//    @extend .ecoIcon;
//    content: "\e914";
//}
//
//.ecoIcon-task-group:before {
//    @extend .ecoIcon;
//    content: "\e915";
//}
//
//.ecoIcon-task-overdue:before {
//    @extend .ecoIcon;
//    content: "\e916";
//}
//
//.ecoIcon-trash:before {
//    @extend .ecoIcon;
//    content: "\e917";
//}
//
//.ecoIcon-unlink:before {
//    @extend .ecoIcon;
//    content: "\e927";
//}
//
//.ecoIcon-upload:before {
//    @extend .ecoIcon;
//    content: "\e918";
//}
//
//.ecoIcon-upload-photo:before {
//    @extend .ecoIcon;
//    content: "\e919";
//}
//
//.ecoIcon-user:before {
//    @extend .ecoIcon;
//    content: "\e91a";
//}
//
//.ecoIcon-violation:before {
//    @extend .ecoIcon;
//    content: "\e91b";
//}