.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
// @import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
// @import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
// @import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';

