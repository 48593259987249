// Variable overrides

$pb-smallest-font-size: 0.6875rem; //equivalent 11px
$pb-small-font-size: 0.75rem; //equivalent 12px
$pb-field-font-size: $pb-small-font-size; //equivalent 12px
$pb-medium-font-size: 0.8125rem; //equivalent 13px
$pb-base-font-size: 0.875rem; //equivalent 14px
$pb-root-font-size: 0.9375rem; //equivalent 15px
$pb-h1-font-size: 1.625rem; //equivalent 28px
$pb-h2-font-size: 1.375rem; //equivalent 22px
$pb-h3-font-size: 1.25rem; //equivalent 20px
$pb-h4-font-size: 1.125rem; //equivalent 18px
$pb-h5-font-size: 1rem; //equivalent 16pxececec

// _typography_variables_weight
$pb-font-weight-light: 300;
$pb-font-weight-normal: 400;
$pb-font-weight-bold: 700;

$pb-font-light: 'SF UI Display Light';
$pb-font-medium: 'SF UI Display Medium';
$pb-font-semibold: 'SF UI Display Semibold';
$pb-font-bold: 'SF UI Display Bold';
$pb-font-black: 'SF UI Display Black';

$pb-color-theme-background: #f7f7f7;
$pb-color-theme-border: #cacfd5;
$pb-color-theme-table-border: #efefef;
$pb-color-dark-2: #333333;
$pb-color-dark: #000000;
$pb-color-gray: #4a4a4a;
$pb-color-gray-2: #666666;
$pb-color-gray-light: #4d4f4b;
$pb-border-color-gray: #e6e6e6;
$pb-color-primary: #32076e;
$pb-color-primary-hover: #28c693;
$pb-color-secondary: #28c693;
$pb-color-secondary-hover: #3a3a3a;
$pb-color-secondary-border: #cccbcc;
$pb-color-tertiary: #ffffff;
$pb-color-tertiary: #ffffff;
$pb-color-blue2: #4768b6;
$pb-color-success: #4a7f35;
$pb-color-success-background: #eef7e4;
$pb-color-warning: #8e7728;
$pb-color-warning-background: #f9f5cd;
$pb-color-error: #c33c48;
$pb-color-violation: #ff0000;
$pb-color-purple: #734dae;
$color-tomato: #d15a5a;
$pb-color-error-background: #ffe9f1;
$pb-color-content: #23282c;
$pb-color-orange: #fa6400;
$pb-color-blue3: #2f718f;
$pb-color-blue4: #0079a4;
$pb-color-purple2: #582c82;

$pb-color-link: #2f9ac3;
$pb-color-link-hover: lighten($pb-color-link, 10);
$pb-input-icon-color: #878d84;

$pb-color-instruction: #3bbc83;

// _spacing_variables
$pb-base-padding: 5px;
$pb-base-margin: 5px;

$pb-small-padding: $pb-base-padding * 2;
$pb-small-margin: $pb-base-margin * 2;

$pb-medium-padding: $pb-base-padding * 3;
$pb-medium-margin: $pb-base-margin * 3;

$pb-large-padding: $pb-base-margin * 4;
$pb-large-margin: $pb-base-margin * 4;

@mixin transitions($transitions...) {
  -webkit-transition: $transitions;
  -o-transition: $transitions;
  transition: $transitions;
}
