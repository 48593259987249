@import "../../../scss/variables";

.lockscreens-sec {

  .logout-btn {
    text-align: right;
    margin-bottom: 30px;

    .btn-primary {
      background: none !important;
      padding: 0;
      margin: 0;
      border: 0;
      border-radius: 0;
      width: auto;
      min-height: unset;
      box-shadow: none;
      display: inline-block;
      font-size:25px;
    }
  }

  .logo {
    margin-bottom: 30px;
  }

}

.disabledDiv {
  pointer-events: none;
  opacity: 0.4;
}

.lockscreen-number-box {
  overflow: hidden;
  border: 1px solid rgba(#ffffff, 0.2);
  border-radius: 20px;

  .num {
    float: left;
    width: 33.33%;
    height: 80px;
    color: $pb-color-tertiary;
    text-align: center;
    font-size: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    cursor: pointer;
    border-right: 1px solid rgba(#ffffff, 0.2);
    border-bottom: 1px solid rgba(#ffffff, 0.2);
    @include transitions(0.5s);

    &:nth-child(3n) {
      border-right: 0;
    }

    &:nth-last-child(-n+3) {
      border-bottom: 0;
    }

    svg {
      fill: $pb-color-tertiary;
    }

    &:hover {
      background: rgba(#ffffff, 0.1);
    }

  }
}

.lockscreen-pin-field {
  text-align: center;

  .label {
    color: $pb-color-tertiary;
    font-size: 17px;
    margin-bottom: 15px;
  }

  .pin-print-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    margin-bottom: 15px;

    .star {
      margin: 0 6px;
      font-size: 30px;
      color: $pb-color-tertiary;
    }
  }
}