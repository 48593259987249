@import '../../../scss/variables';

.signInBody {
  background: url(../../../assets/img/login-bg-blur.jpg) no-repeat center
    #2f1b4e;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginSection {
  width: 370px;
  margin: 0 auto;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;

  .logo {
    text-align: center;
    width: 246px;
    display: block;
    margin: 0 auto 60px auto;

    img {
      width: 100%;
    }
  }

  .desc {
    color: $pb-color-tertiary;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
}

.login-field-box {
  background: $pb-color-tertiary;
  padding: 0;
  border-radius: 30px;
  overflow: hidden;

  .form-group {
    margin-bottom: 0;
  }

  .field-group {
    position: relative;
    padding: 18px 20px 18px 50px;
    border-bottom: 1px solid #d6cde2;

    &.no-border {
      border-bottom: 0;
    }

    .leading-icon {
      position: absolute;
      left: 17px;
      top: 21px;
      font-size: 17px;
      color: $pb-color-primary;
    }

    .icon-input {
      display: flex;
      align-items: center;

      .trailing-icon {
        font-size: 17px;
        color: $pb-color-primary;
      }
    }
  }

  .reset-pass-field {
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 10px;
  }

  .field-disabled {
    pointer-events: none;
    background: #e8e2ec;

    input {
      background: none;
      opacity: 0.5;
    }
  }

  input {
    border-radius: 0;
    padding: 0;
    height: auto;
    font-size: 17px;
    color: $pb-color-gray;
    outline: none;
    box-shadow: none !important;
    border: 0 !important;

    &::-webkit-input-placeholder {
      color: $pb-color-gray;
    }

    &:-ms-input-placeholder {
      color: $pb-color-gray;
    }

    &::placeholder {
      color: $pb-color-gray;
    }
  }

  .lang-box {
    background: #e7e3ed;
    display: flex;
    width: 95%;
    border-radius: 50px;
    margin: 10px auto;
    padding: 4px;

    button {
      background: none;
      border: 0;
      margin: 0 !important;
      color: $pb-color-primary !important;
      min-height: 44px;
      background: none !important;
      box-shadow: none !important;

      &:focus {
        background: none !important;
      }

      &.active {
        background: $pb-color-tertiary !important;
        color: $pb-color-primary !important;
        font-family: $pb-font-bold;
      }
    }
  }

  &.forgot-pass {
    margin-top: 0;
    border-radius: 10px;
  }

  .error-message {
    margin-left: 0;
  }
}

.generate-field-box {
  margin-top: 100px;
}

.login-btn {
  button {
    margin-top: 20px;
  }
}

a.forgotPass {
  display: block;
  text-align: center;
  color: $pb-color-secondary;
  font-family: $pb-font-medium;
  font-size: 18px;
  margin-top: 30px;
  text-decoration: none;
  @include transitions(0.5s);
  cursor: pointer;
}

.full-page-loader {
  position: relative;
  right: auto;
  top: auto;
  display: inline-block;
  margin-left: $pb-small-margin;
}
