@import '../../scss/variables';

#root,
html,
body {
  height: 100%;
}

.main-header-sec {
  padding: 20px 17px 20px 17px;
  background: rgb(80, 42, 124);
  background: -moz-linear-gradient(
    left,
    rgba(80, 42, 124, 1) 0%,
    rgba(17, 35, 68, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(80, 42, 124, 1) 0%,
    rgba(17, 35, 68, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(80, 42, 124, 1) 0%,
    rgba(17, 35, 68, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#502a7c', endColorstr='#112344', GradientType=1);

  .head-icon {
    cursor: pointer;

    svg {
      fill: $pb-color-tertiary;
      @include transitions(0.5s);
    }

    &.right-align {
      text-align: right;
      float: right;
    }

    &.left-align {
      text-align: left;
      float: left;
    }
  }

  .hald-icon-box {
    display: inline-block;
    margin-left: 20px;
    position: relative;

    svg {
      fill: $pb-color-tertiary;
      @include transitions(0.5s);
    }
  }

  .filter-icon {
    color: $pb-color-tertiary;
    font-size: 23px;
    position: absolute;
    left: -5px;
    top: 0;
  }
}

.main-header-title {
  font-size: 34px;
  font-family: $pb_font-bold;
  color: $pb-color-tertiary;
  line-height: 35px;
  // margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-header-title-center {
  font-size: 25px;
  font-family: $pb_font-bold;
  color: $pb-color-tertiary;
  line-height: 35px;
  margin-top: 10px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-search-bar {
  position: relative;
  margin-top: 13px;

  input {
    font-size: 18px;
    color: $pb-color-dark !important;
    padding: 15px 20px;
    height: 50px;
    border: 1px solid $pb-border-color-gray;
    border-radius: 30px;

    &::-webkit-input-placeholder {
      color: #b3b3b3;
    }

    &:-ms-input-placeholder {
      color: #b3b3b3;
    }

    &::placeholder {
      color: #b3b3b3;
    }
  }

  i {
    position: absolute;
    top: 14px;
    right: 17px;
    font-size: 22px;
    color: $pb-color-primary;
  }
}

.parent-scrollable-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.scrollable-content-box-without-bottom-bar {
  flex: 1;
  overflow: auto;

  .section-item {
    display: block;
    border: 0;
    height: auto;
    padding: 0;
  }

  .sectionlist-container {
    display: block;
    padding-top: 0;
    background: none;
  }

  .section-container {
    margin-bottom: 0;
  }
}

.scrollable-content-box {
  flex: 1;
  margin-bottom: 63px;
  overflow: auto;

  .section-item {
    display: block;
    border: 0;
    height: auto;
    padding: 0;
  }

  .sectionlist-container {
    display: block;
    padding-top: 0;
    background: none;
  }

  .section-container {
    margin-bottom: 0;
  }

  .section-header {
    background: #f2f2f2;
    font-size: 12px !important;
    font-family: $pb-font-medium;
    padding: 8px 15px;
    color: $pb-color-gray-2;
    display: block;
    // border-top: 1px solid $pb-border-color-gray;
    // border-bottom: 1px solid $pb-border-color-gray;
    text-transform: uppercase;

    .separator-head-bar {
      padding: 0;
    }
  }
}

.no-result-found-box {
  text-align: center;
  padding: 30px 50px;

  p {
    font-family: $pb-font-semibold;
    line-height: 20px;
    font-size: 16px;
  }
}

.tab-screen-parent-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .tab-screen-scrollable-container {
    flex: 1 1;
    overflow: auto;

    &.pos-relative {
      overflow-x: auto;
      margin-bottom: 30px;

      .tab-content {
        position: relative;
      }
    }
  }
}
